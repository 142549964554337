@charset "UTF-8";
/*Dialog Form*/
@media screen and (min-width: 960px) {
  .formDialog {
    width: 50vw; }
  .formDialogSmall {
    width: 30vw; } }

@media screen and (max-width: 959px) {
  .formDialog {
    width: 85vw; }
  .formDialogSmall {
    width: 85vw; } }

/*End Dialog Form*/
/* Custom Color Spinner */
.p-progress-spinner-circle {
  stroke: #065dff !important; }

/*Dialog Logout*/
@media screen and (min-width: 960px) {
  .logoutDialog {
    width: 30vw; } }

@media screen and (max-width: 959px) {
  .logoutDialog {
    width: 85vw; } }

/*End Dialog Logout*/
/* Input tipo file*/
.input-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block; }

/*Toast message*/
@media screen and (max-width: 576px) {
  .p-toast {
    width: auto;
    left: 20px; } }

.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  cursor: pointer; }
  .login-with-google-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25); }
  .login-with-google-btn:active {
    background-color: #eeeeee; }
  .login-with-google-btn:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc; }
  .login-with-google-btn:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }

.loginLogo img {
  width: 100%;
  max-width: 180px;
  height: auto; }

.logo img {
  width: 100%;
  max-width: 130px;
  height: auto; }

.menubar {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem; }
  .menubar .menubar-end {
    margin-left: auto;
    align-self: center; }

.welcomeMsg {
  padding: 0.75rem 1rem;
  display: flex; }

.codeTitle {
  font-size: 40px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px; }

.description {
  font-size: 16px;
  margin-bottom: 20px; }

/*Fix buscador datatable*/
@media (max-width: 767px) {
  .p-datatable .p-input-icon-left {
    width: 100%; }
  .p-datatable .p-inputtext {
    width: 100%; } }

/*Cabecera columnas*/
.p-column-title {
  font-weight: bold !important;
  color: black; }

/*Border separador*/
@media (max-width: 960px) {
  .p-datatable .p-datatable-tbody tr td {
    border: 1px solid #e2e2e2; } }

/*Tamaño de fuente*/
@media (max-width: 576px) {
  .p-datatable-table {
    font-size: 14px; }
  .p-datatable .actionButtons .p-button {
    padding: 5px;
    width: 40px; } }

/*Wrap text*/
@media (max-width: 400px) {
  .text {
    display: inline-block;
    width: 70px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; } }

/*Padding celdas*/
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 1rem; }
